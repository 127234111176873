.description {
    padding: 0;
    max-height: 0px;
}

.gameCard:hover .description {
    display: block !important;
    transition: max-height 0.25s linear;
    max-height: 500px;
    padding: 8px;
}