@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, #root {
  height: 100%;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #969696;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #FF395D;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.loading{
  background: #91273d;
  background: linear-gradient(110deg, #91273d 20%, #d52247 50%, #91273d 80%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  color: transparent;
  user-select: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.loading > * {
  visibility: hidden;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.loading2{
  display: inline-block;
  width: 40px;
  height: 40px;
}

.loading2::after{
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
