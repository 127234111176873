.line {
    width: 100%;
    height: 1px;
    background: #A4E7FF;
}

.shadow {
    box-shadow: 0px 0px 4px 2px rgba(59, 130, 246, 0.8);
}

.progress {
    height: 4px;
    border-radius: 2px;
    background:#A4E7FF;
}